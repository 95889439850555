import React, { useEffect, useState, useCallback } from "react";
import ApiService from '../../apiService';
import SkeletonLoader from "../skeletonloader/SkeletonLoader";
import { toast } from 'react-toastify';
import VendorModel from "../model/VendorModel";
import Pagination from "../shared/Pagination";

const VendorTable = ({ title, showButton }) => {
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [vendorToDelete, setVendorToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false); 

    const [searchQuery, setSearchQuery] = useState("");
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalData, setTotalData] = useState(0);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchQuery(searchQuery);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery]);

    const fetchData = useCallback(async (page = 1) => {
        setLoading(true);
        try {
            const result = await ApiService.auth.getUser('vendor', debouncedSearchQuery, page, '', rowsPerPage);
            if (result?.success) {
                const { data } = result;
                setVendors(data.users || []);
                setTotalPages(data.totalPages || 0);
                setCurrentPage(data.currentPage || 1);
                setTotalData(data.totalUsers);
            }
        } catch (error) {
            toast.error('Failed to fetch vendors.');
        } finally {
            setLoading(false);
        }
    }, [debouncedSearchQuery, rowsPerPage]);

    useEffect(() => {
        fetchData(currentPage);
    }, [fetchData, currentPage, rowsPerPage]);

    const handleEditVendor = (vendor) => {
        setSelectedVendor(vendor);
        setIsModalOpen(true);
    };

    const handleAddVendor = () => {
        setSelectedVendor(null);
        setIsModalOpen(true);
    };

    const openDeleteConfirmation = (vendorId) => {
        setVendorToDelete(vendorId);
        setIsDeleteConfirmationOpen(true);
        document.body.style.overflow = 'hidden';
    };

    const closeDeleteConfirmation = () => {
        setIsDeleteConfirmationOpen(false);
        setVendorToDelete(null);
        document.body.style.overflow = 'unset';
    };

    const handleDelete = async () => {
        if (!vendorToDelete) return;

        setIsDeleting(true); // Set loading state to true
        try {
            await ApiService.auth.delete(vendorToDelete);
            fetchData();
            toast.success('Vendor deleted successfully!');
        } catch (error) {
            toast.error('Failed to delete vendor.');
        } finally {
            setIsDeleting(false); // Reset loading state
            closeDeleteConfirmation();
        }
    };

    return (
        <>
            <div className="flex flex-col space-y-3">
                <div className="flex justify-end">
                    <div className="w-full md:w-4/12">
                        <div className="overflow-hidden border border-gray-300 shadow-d rounded-[20px]">
                            <div className="relative flex items-end justify-end py-2 bg-white">
                                <div className="relative flex items-center w-full">
                                    <input
                                        type="text"
                                        value={searchQuery}
                                        className="w-full py-1 pl-12 pr-4 text-sm font-medium text-gray-700 rounded-[20px] focus:outline-none"
                                        placeholder="Search vendors..."
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <div className="absolute inset-y-0 flex items-center pointer-events-none left-5">
                                        <i className="text-lg text-gray-500 fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4 border bg-white border-border-dark rounded-[20px] overflow-hidden">
                <div className="flex items-center py-2 px-4 justify-center relative">
                    <h1 className="font-bold p-1 text-center leading-8 text-2xl">{title}</h1>
                    {showButton && (
                        <button
                            className="absolute right-4 top-3"
                            onClick={handleAddVendor}
                        >
                            <img src={"/assets/svg/plusbutton.svg"} alt="Add" />
                        </button>
                    )}
                </div>

                <div className="w-full overflow-x-auto border-t border-border-dark min-h-[300px]">
                    {loading && (
                        <SkeletonLoader />
                    )}
                    {!loading && (vendors.length === 0 ? (
                        <p className="text-center py-4">No vendors available</p>
                    ) : (
                        <table className="w-full border-collapse scrolldown">
                            <thead>
                                <tr className="bg-[#F6F6F6] h-[41.9px]">
                                    <th className="text-base font-bold px-4 border-r border-border-dark w-[5%]">S.No.</th>
                                    <th className="text-base font-bold px-4 border-r border-border-dark w-[20%]">Username</th>
                                    <th className="text-base font-bold px-4 border-r border-border-dark w-[30%]">Email</th>
                                    <th className="text-base font-bold px-4 w-[10%]">Action</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white w-full">
                                {vendors?.map((vendor, index) => (
                                    <tr key={vendor._id} className="border-b border-border-dark">
                                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                                            {(currentPage - 1) * rowsPerPage + index + 1}
                                        </td>
                                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">{vendor?.name}</td>
                                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">{vendor?.email}</td>
                                        <td className="text-base font-bold px-2 py-3 flex justify-center items-center gap-1">
                                            <button
                                                className="text-sm font-bold text-black"
                                                onClick={() => handleEditVendor(vendor)}
                                            >
                                                Edit
                                            </button>
                                            |
                                            <button
                                                className="text-sm font-bold text-[#C30000]"
                                                onClick={() => openDeleteConfirmation(vendor._id)}
                                            >
                                               Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ))}
                </div>

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    rowsPerPage={rowsPerPage}
                    onPageChange={setCurrentPage}
                    totalData={totalData}
                    loading={loading}
                    onRowsPerPageChange={(e) => {
                        setRowsPerPage(+e.target.value);
                        setCurrentPage(1);
                    }}
                />

                <VendorModel
                    isOpen={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false);
                        setSelectedVendor(null);
                    }}
                    fetchData={fetchData}
                    vendor={selectedVendor}
                />

                {isDeleteConfirmationOpen && (
                    <div className="fixed inset-0 z-[1000] bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white rounded-lg p-4">
                            <p className="text-xl font-semibold py-10">Are you sure you want to delete this vendor?</p>
                            <div className="flex justify-end mt-4 font-semibold">
                                <button 
                                    className={`bg-[#AC1218] text-white p-2 font-semibold text-base rounded-md ${isDeleting ? 'opacity-50 px-6 cursor-not-allowed' : ''}`} 
                                    onClick={handleDelete} 
                                    disabled={isDeleting}
                                >
                                    {isDeleting ? <div className="loader" /> : "Delete"}
                                </button>
                                <button 
                                    className="ml-4 border border-border-color p-2 text-base rounded-md" 
                                    onClick={closeDeleteConfirmation} 
                                    disabled={isDeleting}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default VendorTable;
