import axios from '../helpers/axios';
import { handleErrorToaster } from '../utils/function';

class ApiHistoryService {
    async getAll({ page, limit = 10, userId = '', campaignId = '' }) {
        try {
            const response = await axios.get(`/api-history?page=${page}&limit=${limit}&userId=${userId}&campId=${campaignId}`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw error;
        }
    }

    async getUsers() {
        try {
            const response = await axios.get(`/api-history/users`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw error;
        }
    }

    async getCampaigns() {
        try {
            const response = await axios.get(`/api-history/campaigns`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw error;
        }
    }
}

export default new ApiHistoryService();
