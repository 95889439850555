import React, { useCallback, useEffect, useState } from "react";
import apiService from "../../apiService";
import { getUserRoleFromToken, isAdmin } from "../../utils/auth";
import { getLeadData, getLeadStatus } from "../../utils/function";
import { Link, useNavigate } from "react-router-dom";
import CustomSkeleton from "../shared/Skeleton";

const LeadList = ({ loading }) => {
  const [leads, setLeads] = useState([])
  const userType = getUserRoleFromToken()
  const isUserAdmin = isAdmin();
  const navigate = useNavigate();

  const fetchLeads = useCallback(async () => {
    const data = await apiService.leads.getLeads({
      limit: 3,
      userType,
      page: 1
    })
    setLeads(data?.data?.leads)
  }, [userType])

  useEffect(() => {
    fetchLeads()
  }, [fetchLeads])

  function formatLeadId(leadId) {
    const cleanId = leadId.replace('lead-', '');
    return cleanId.length > 4 ? cleanId.slice(-4) : cleanId;
  }

  return (
    <div className="bg-white rounded-[18.47px]">
      <div className="px-5 border-b border-[#94A3B8] py-3">
        <p className="w-full text-start font-bold">Leads List in</p>
      </div>
      <div className="overflow-x-auto">
        {loading ? <CustomSkeleton height="90px" count={2} /> :
          leads?.length
            ?
            leads?.map((lead, index) => (
              <div
                key={index}
                className="card py-3 bg-[white] flex xl:gap-6 gap-4 border-[#94A3B8] border-b-[0.5px] hover:bg-[#F8FAFC] px-5"
                style={{ cursor: isUserAdmin ? "pointer" : "default" }}
                onClick={() => {
                  if (isUserAdmin && !loading) {
                    navigate(`/crm/leads/view/${lead.leadId}`);
                  }
                }}
              >
                <div className="xl:h-[62px] h-12 w-12 border-2 xl:w-[62px] rounded-[50%]  shrink-0 flex items-center justify-center">
                  <p className="font-[600] text-gray-600 text-center">
                    {formatLeadId(lead?.leadId)}
                  </p>
                </div>
                <div style={{ flex: 1.7 }} className="flex justify-center flex-col">
                  <p>{getLeadData(lead?.responses, "name")}</p>
                  <p>{lead?.createdBy?.name}</p>
                  {/* Created by */}
                </div>
                <div style={{ flex: 2.5 }} className="flex justify-center flex-col">
                  {getLeadStatus(lead.status, userType)}
                  <p>{lead?.createdBy?.email}</p>
                </div>
                <div style={{ flex: 1.5 }} className="flex justify-center flex-col">
                  <p>{lead?.campaign?.title || '-'}</p>
                  <p>{lead?.createdBy?.userType}</p>
                </div>
              </div>
            ))
            :
            <p className="card py-3 border-[#94A3B8] border-b-[0.5px] px-5 text-center w-full">No Leads found.</p>}
      </div>
      {
        leads?.length > 0 &&
        <div className="flex justify-end mt-3">
          {!loading && <Link to="/crm/leads" className="p-2 rounded-md text-sm font-semibold text-blue-510 bg-none underline px-5">Show more</Link>}
        </div>
      }
    </div>
  );
};

export default LeadList;
