import React from "react";
import useBodyOverflow from "../../hooks/useBodyOverflow";

const ApiLogsModel = ({ isOpen, onClose, json }) => {
    useBodyOverflow(isOpen);

    if (!isOpen) return null;

    const renderContent = (content) => {
        if (typeof content === 'string') {
            try {
                const parsed = JSON.parse(content); // Try parsing the string as JSON
                return JSON.stringify(parsed, null, 2); // Format it nicely if it's JSON
            } catch (error) {
                return content; // Return the original string if it's not JSON
            }
        }
        else if (typeof content === 'object' && content !== null) {
            return JSON.stringify(content, null, 2);
        }
        return content;
    };

    return (
        <div className="modal-overlay">
            <div className="modal pt-5 sm:px-8 sm:pb-10 max-h-[90%] px-4 pb-5 relative max-w-[700px] mx-auto w-full">
                <div className="flex justify-end absolute top-5 right-5">
                    <button
                        type="button"
                        className="rounded-full h-[30px] w-[30px] text-sm border-2 border-black font-semibold"
                        onClick={onClose}
                    >
                        <i className="fa-solid text-black text-lg fa-xmark"></i>
                    </button>
                </div>
                <pre className="bg-gray-100 p-2 rounded mt-11 overflow-y-auto max-h-[450px] mx-auto w-full"
                    style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word"
                    }}
                >{renderContent(json)}</pre>
            </div>
        </div>
    );
};

export default ApiLogsModel;
