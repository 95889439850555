import React from 'react'
import LeadHistoryTable from '../../components/table/LeadHistory'

const LeadHistory = () => {
  return (
    <>
      <LeadHistoryTable title={'Api history'} />
    </>
  )
}

export default LeadHistory