import React, { useCallback, useEffect, useState } from "react";
import apiService from "../../apiService";
import { useParams } from "react-router-dom";
import Skeleton from "../skeletonloader/Request";
import { getUserRoleFromToken } from "../../utils/auth";
import { ReactComponent as DocumentIcon } from "../../assets/icons/document.svg";
import { ReactComponent as AudioIcon } from "../../assets/icons/video.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import {
  convertDateWithTimeZone,
  getStatusByValue,
  getStatusColorClass,
  handleDownload,
} from "../../utils/function";
import { allStatus } from "../../utils/constant";
import ApiLogsModel from "../model/ShowApiLogs";

const errorCode = [400, 401, 500, 501];

const AdminLeadView = () => {
  const { id: leadId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const userType = getUserRoleFromToken();
  const [openIndex, setOpenIndex] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [modelData, setModelData] = useState({});

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await apiService.leads.getById(leadId);
      if (result?.success) {
        setData(result.data);
        setOpenIndex(result.data.responses.map((_, index) => index));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId, userType]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleAccordion = (index) => {
    setOpenIndex((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
    <div className="mt-4">
      {loading ? (
        <Skeleton count={4} />
      ) : (
        <>
          {/*    data show start */}
          <div className=" flex gap-5 mt-5 max-sm:flex-col flex-wrap">
            <div className="md:w-[48%] w-full flex gap-2 flex-col">
              {/* Created */}
              <div className="flex w-full items-center gap-5 py-7 pl-5 bg-white p-3 border border-[#E5E7EB] rounded-md">
                <img
                  src="/assets/svg/leads/user.svg"
                  alt=""
                  className="h-10 w-10"
                />
                <h1 className="text-xl font-medium text-center">
                  <b className="block leading-[1] text-left">Created By </b>
                  <span className="text-left text-base text-[#868686]">
                    {data?.createdBy?.name} {`(${data?.createdBy?.userType})`}
                  </span>
                </h1>
              </div>

              {/* status */}
              <div className="flex w-full items-center gap-5 py-7 pl-5 bg-white p-3 border border-[#E5E7EB] rounded-md">
                <img
                  src="/assets/svg/leads/status.svg"
                  alt=""
                  className=" h-10 w-10"
                />
                <h1 className="text-xl font-medium">
                  <b className=" block"> Status: </b>
                  <span className="  text-center text-base text-[#868686]">
                    {allStatus.find((s) => s?.value === data?.status)?.label}
                  </span>
                </h1>
              </div>
            </div>
            {/* detail */}

            <div className="flex md:w-[48%] w-full flex-col gap-2 py-5 bg-white pl-5 px-3 border border-[#E5E7EB] rounded-md ">
              <div className="text-xl font-medium flex gap-5 items-center">
                <img
                  src="/assets/svg/details.svg"
                  alt="details"
                  className=" h-10 w-10"
                />
                <b className="block">Details</b>
              </div>
              <div className="mt-5 flex flex-col gap-3">
                {data.createdAt && (
                  <h2 className="text-base w-full flex justify-between">
                    <b>Lead in time:</b> {convertDateWithTimeZone(data?.createdAt)}
                  </h2>
                )}
                <h2 className="text-base w-full flex justify-between">
                  <b>Lead out time:</b>
                  {convertDateWithTimeZone(data?.leadOutTime) || '-'}
                </h2>
                <h2 className="text-base w-full flex justify-between">
                  <b>Last sent to:</b> {data?.lastClient?.name || "-"}
                </h2>
              </div>
            </div>
          </div>
          {/* data show end */}

          <div className="mt-7">
            <p className="text-start rounded-tl-[10px] custom-bg pl-4 pr-[4rem] text-nowrap sm:pr-32 py-3 max-sm:text-base text-lg font-semibold inline capitalize  text-white ">
              question and answer
            </p>
            <div className=" mt-2 bg-white pb-5 rounded-[10px] rounded-l-none border border-[#7FB8EF]">
              <div className="flex gap-3 w-full mt-5 max-md:flex-col justify-center px-5 items-center">
                {data?.media?.map((file, index) => (
                  <div
                    className="flex items-center w-full justify-between pr-3 bg-[#F9F9F9] p-3"
                    key={file._id}
                  >
                    <div className="flex gap-2 items-center">
                      <b>
                        {file.type === "doc"
                          ? "Submitted document"
                          : "Submitted video/audio"}
                      </b>
                      {file.type === "doc" ? (
                        <DocumentIcon />
                      ) : file.type === "recording" ? (
                        <AudioIcon />
                      ) : null}
                    </div>
                    <DownloadIcon
                      className="cursor-pointer"
                      onClick={() =>
                        handleDownload(file.url, file.url.split("/").pop())
                      }
                    />
                  </div>
                ))}
              </div>

              <div className="rounded-md mt-2 overflow-x-auto grid grid-cols-1 sm:grid-cols-2 sm:px-5 px-3 flex-wrap gap-1 items-center max-h-[500px] overflow-y-auto justify-start">
                {data?.responses?.map((resp, index) => (
                  <div key={index} className="  w-full">
                    <div
                      onClick={() => toggleAccordion(index)}
                      className="flex items-center justify-between p-3 cursor-pointer"
                    >
                      <h1 className=" text-lg font-medium flex items-start">
                        <span className=" relative h-[25px] mr-4 rounded-full bg-[#F9F9F9] w-[25px] flex justify-center items-center text-[#868686]">
                          {index + 1}{" "}
                        </span>{" "}
                        {resp?.questionTitle}
                      </h1>
                      <b
                        className={`text-lg transition-transform duration-300 ease-in-out ${openIndex.includes(index) ? "rotate-180" : "rotate-0"
                          }`}
                      >
                        {openIndex.includes(index) ? "-" : "+"}
                      </b>
                    </div>
                    <div
                      className={`transition-all duration-300 ease-in-out overflow-hidden ${openIndex.includes(index)
                        ? "max-h-96 opacity-100"
                        : "max-h-0 opacity-0"
                        }`}
                    >
                      <div className="px-3 pb-3 ml-2">
                        <p className="ml-7">{resp?.response}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* api logs start */}
          {data?.apiLogs?.length > 0 && (
            <div className=" mt-[30px]">
              <p className="text-start rounded-tl-[10px] max-sm:text-base custom-bg pl-4  mt-2 h-10 my-auto flex items-center text-lg font-semibold max-w-[210px] capitalize  text-white ">
                Api logs
              </p>
              <div className=" bg-white border border-[#7FB8EF]  p-5 rounded-tl-none rounded-md overflow-hidden">
                <div className="w-full overflow-x-auto max-h-[300px] overflow-y-auto ">
                  <table className="w-full border-collapse scrolldown border border-[#E5E7EB] rounded-[5px]">
                    <thead className="bg-[#F9F9F9] sticky top-0">
                      <tr className="h-[41.9px] w-full">
                        <th className="text-base font-bold px-4 border-r border-[#E5E7EB]">
                          S.No.
                        </th>
                        <th className="text-base font-bold px-4 border-r border-[#E5E7EB]">
                          Created At
                        </th>
                        <th className="text-base font-bold px-4 border-r border-[#E5E7EB]">
                          Response Status Code
                        </th>
                        <th className="text-base font-bold px-4 border-r border-[#E5E7EB]">
                          Error
                        </th>
                        <th className="text-base font-bold px-4 border-r border-[#E5E7EB]">
                          Request
                        </th>
                        <th className="text-base font-bold px-4 border-r border-[#E5E7EB]">
                          Response
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white w-full">
                      {data.apiLogs?.map((log, index) => (
                        <tr key={log.id} className="border-b border-[#E5E7EB]">
                          <td className="text-sm text-center  px-2 py-3 border-r border-[#E5E7EB]">
                            {index + 1}
                          </td>
                          <td className="text-sm text-center  px-2 py-3 border-r border-[#E5E7EB]">
                            {convertDateWithTimeZone(log.createdAt)}
                          </td>
                          <td className={`text-sm text-center  px-2 py-3 border-r border-[#E5E7EB] ${getStatusColorClass(log.responseStatusCode)}`}>
                            {log.responseStatusCode}
                          </td>
                          <td className="text-sm text-center  px-2 py-3 border-r border-[#E5E7EB]">
                            {errorCode.includes(log.responseStatusCode) ? (
                              log?.response?.data?.message ||
                              log?.response?.message
                            ) : (
                              <p className="text-center">-</p>
                            )}
                          </td>
                          <td className="text-sm text-center font-bold px-2 py-3 border-r border-[#E5E7EB]">
                            <button
                              className={`text-sm py-2 px-6 bg-black rounded-2xl mx-auto text-white font-semibold`}
                              onClick={() => {
                                setModelData(log?.requestBody);
                                setShowModel(true);
                              }}
                            >
                              Show
                            </button>
                          </td>
                          <td className="text-sm text-center font-bold px-2 py-3 border-r border-[#E5E7EB]">
                            <button
                              className={`text-sm py-2 px-6 bg-black rounded-2xl mx-auto text-white font-semibold`}
                              onClick={() => {
                                setModelData(log?.response || {});
                                setShowModel(true);
                              }}
                            >
                              Show
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {/* api logs end */}

          {/* Sub admin history start */}
          {data?.leadHistory?.length > 0 && (
            <div className=" mt-[30px]">
              <p className="text-start rounded-tl-[10px] max-sm:text-base custom-bg pl-4  mt-2 h-10 my-auto flex items-center text-lg font-semibold max-w-[210px] capitalize  text-white ">
                Lead History
              </p>
              <div className=" bg-white border border-[#7FB8EF]  p-5 rounded-tl-none rounded-md overflow-hidden">
                <div className="w-full overflow-x-auto max-h-[300px] overflow-y-auto ">
                  <table className="w-full border-collapse scrolldown border border-[#E5E7EB] rounded-[5px]">
                    <thead className="bg-[#F9F9F9] sticky top-0">
                      <tr className="h-[41.9px] w-full">
                        <th className="text-base font-bold px-4 border-r border-[#E5E7EB]">
                          Updated At
                        </th>
                        <th className="text-base font-bold px-4 border-r border-[#E5E7EB]">
                          Update By
                        </th>
                        <th className="text-base font-bold px-4 border-r border-[#E5E7EB]">
                          User Type
                        </th>
                        <th className="text-base font-bold px-4 border-r border-[#E5E7EB]">
                          Change
                        </th>
                        <th className="text-base font-bold px-4 border-r border-[#E5E7EB]">
                          Client
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white w-full">
                      {data.leadHistory?.map((log, index) => {
                        const {
                          createdAt = "",
                          updatedBy,
                          sentTo = "",
                          updateType,
                          previousStatus = '',
                          currentStatus = "",
                          note = ''
                        } = log

                        return (<tr key={log.id} className="border-b border-[#E5E7EB]">
                          <td className="text-sm text-center  px-2 py-3 border-r border-[#E5E7EB]">
                            {convertDateWithTimeZone(createdAt)}
                          </td>
                          <td className="text-sm text-center  px-2 py-3 border-r border-[#E5E7EB]">
                            {updatedBy?.name}
                          </td>
                          <td className="text-sm text-center  px-2 py-3 border-r border-[#E5E7EB]">
                            {updatedBy?.userType}
                          </td>
                          <td className="text-sm text-center  px-2 py-3 border-r border-[#E5E7EB]">
                            {updateType === "statusChange" ?
                              <p>
                                <span>{getStatusByValue(previousStatus)}</span>
                                <b>{' -> '}</b>
                                <span>{getStatusByValue(currentStatus)}</span>
                              </p>
                              : note || '-'}
                          </td>
                          <td className="text-sm text-center font-bold px-2 py-3 border-r border-[#E5E7EB]">
                            {sentTo ? sentTo?.name : "-"}
                          </td>
                        </tr>)
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {/* Sub admin history end */}
        </>
      )}

      <ApiLogsModel
        isOpen={showModel}
        onClose={() => setShowModel(false)}
        json={modelData}
      />
    </div>
  );
};

export default AdminLeadView;
