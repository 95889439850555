import { jwtDecode } from "jwt-decode";
import { sidebarDataForHeader } from "../data/menuList";

export const getUserRoleFromToken = () => {
  const token = localStorage.getItem("accessToken");

  if (!token) return null;

  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.userType;
  } catch (error) {
    return null;
  }
};

export const getStaffRoleFromToken = () => {
  const token = localStorage.getItem("accessToken");
  if (!token) return null;

  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.role;
  } catch (error) {
    return null;
  }
};

export const redirectToRoleBasedRoute = (token, navigate) => {
  try {
    const decodedToken = jwtDecode(token);
    if (!decodedToken.userType) {
      localStorage.removeItem('accessToken')
      window.location.reload()
    }
    const userRole = decodedToken.userType;

    if (userRole === "admin" || userRole === 'vendor' || userRole === 'staff' || userRole === 'subAdmin') {
      navigate("/crm/dashboard");
    } else if (userRole === "client") {
      navigate("/crm/dashboard");
      // navigate("/crm/clients");
    } else {
      navigate("/unauthorized");
    }
  } catch (error) {
    navigate("/unauthorized");
    localStorage.removeItem('accessToken')
  }
};

export const logOutUser = () => {
  localStorage.removeItem('accessToken')
  window.location.reload()
}

export const getUserMenuByRole = (userRole) => {
  const role = getStaffRoleFromToken();

  const isMenuAllowed = (menu) => {
    if (userRole === 'staff') {
      if (menu.name === 'Request' && role.length && !role.includes("verifier")) {
        return false;
      }
      if (menu.name === 'Leads' && role.length && !role.includes("lead_management")) {
        return false;
      }
    }
    return menu.role.includes(userRole);
  };

  return sidebarDataForHeader.filter(isMenuAllowed);
};


export const isStaffVerifier = () => {
  const userRole = getUserRoleFromToken()
  const role = getStaffRoleFromToken()

  if (userRole === 'staff' && role?.length && role.includes('verifier')) {
    return true
  } else {
    return false
  }
}

export const isAdmin = () => {
  const userRole = getUserRoleFromToken()

  return userRole === 'admin'
}