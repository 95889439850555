import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CustomSkeleton = ({ count = false, height = '100%' , width = '100%' }) => {
    if (count) {
        return (
            <>
                {Array.from({ length: count + 1 }).map((_, index) => (
                    <Skeleton key={index} height={height} width={width} style={{ borderRadius: "8px" }} />
                ))}
            </>
        );
    }

    return <Skeleton height={height} width={width} style={{ borderRadius: "8px" }} />;
};

export default CustomSkeleton
