import React from 'react'
import ClientsTable from '../../components/table/Clients';

const Clients = () => {
  return (
    <>
      <ClientsTable title={"Clients"} showButton={true} />
    </>
  )
}

export default Clients
