import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { max } from 'date-fns';

ChartJS.register(ArcElement, Tooltip, Legend);

function isLight(color) {
    const hex = color?.replace('#', '');
    const r = parseInt(hex?.substr(0, 2), 16);
    const g = parseInt(hex?.substr(2, 2), 16);
    const b = parseInt(hex?.substr(4, 2), 16);
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186;
}

function DynamicDoughnutChart({ data }) {
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                },
                onClick: null,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    title: () => 'Status',
                    labelTextColor: (context) => {
                        const bgColor = context.dataset.backgroundColor[context.dataIndex];
                        return isLight(bgColor) ? '#000' : '#fff';
                    },
                    label: (tooltipItem) => {
                        const label = tooltipItem.label
                        const value = tooltipItem.parsed;
                        return `${label}: ${value}`;
                    },
                },
            },
        },
        animation: {
            animateRotate: true,
            animateScale: true
        },
        rotation: -90,
    };

    const chartData = {
        labels: data.labels,
        datasets: data?.datasets
    };

    return (
        <Doughnut data={chartData} options={chartOptions} />
    );
}

export default function App({ data }) {
    return <DynamicDoughnutChart data={data} />;
}
