import React, { useCallback, useEffect, useMemo, useState } from 'react';
import apiService from '../../apiService';
import SkeletonLoader from "../skeletonloader/SkeletonLoader";
import Pagination from "../shared/Pagination";
import ApiLogsModel from '../model/ShowApiLogs';
import { getStatusColorClass } from '../../utils/function';
const Dropdown = React.lazy(() => import('react-dropdown'));

const LeadHistoryTable = ({ title }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modelData, setModelData] = useState({});
    const [usersList, setUsersList] = useState([]);
    const [campaignsList, setCampaignsList] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedCamp, setSelectedCamp] = useState("select");

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const result = await apiService.apiHistory.getAll({
                page: currentPage,
                limit: rowsPerPage,
                userId: selectedUser,
                campaignId: selectedCamp
            });
            if (result) {
                setData(result.data.data || []);
                setTotalPages(result.data.totalPages);
                setTotalData(result.data.totalData);
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        } finally {
            setLoading(false);
        }
    }, [currentPage, rowsPerPage, selectedUser, selectedCamp]);

    const fetchInitialData = useCallback(async () => {
        try {
            const [userResults, campaignResults] = await Promise.all([
                apiService.apiHistory.getUsers(),
                apiService.apiHistory.getCampaigns()
            ]);
            setUsersList(userResults.data || []);
            setCampaignsList(campaignResults.data || []);
        } catch (error) {
            console.error('Failed to fetch initial data:', error);
        }
    }, []);

    useEffect(() => {
        fetchInitialData();
    }, [fetchInitialData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleOpenModal = useCallback((body) => {
        if (body) {
            setModelData(body);
            setShowModal(true);
        }
    }, []);

    const campaignOptions = useMemo(() => [
        { label: "Select Campaign", value: "select" },
        { label: "All", value: "" },
        ...campaignsList,
    ], [campaignsList]);

    const userOptions = useMemo(() => [
        { label: 'All', value: '' },
        ...usersList
    ], [usersList]);

    return (
        <div className="border bg-white border-border-dark mt-4 rounded-[20px] overflow-hidden">
            <div className="flex items-center justify-between py-1 px-4 relative">
                <h1 style={{ flex: 1.2 }} className="font-bold p-2 text-end leading-8 text-2xl">{title}</h1>

                <div className="flex-1 flex max-sm:flex-wrap  justify-center md:justify-end items-center max-md:pb-4 gap-2 col-span-2 sm:col-span-1">
                    <Dropdown
                        options={campaignOptions}
                        onChange={(option) => setSelectedCamp(option.value)}
                        value={selectedCamp}
                        placeholder="All"
                        className="font-medium shrink-0 min-w-dropdown"
                    />
                    <Dropdown
                        options={userOptions}
                        onChange={(option) => setSelectedUser(option.value)}
                        value={selectedUser}
                        placeholder="Select User"
                        className="font-medium shrink-0 min-w-dropdown"
                    />
                </div>
            </div>
            <div className="w-full border-t border-border-dark min-h-[300px] overflow-x-auto">
                {loading ? <SkeletonLoader /> : (
                    data.length > 0 ? (
                        <table className="w-full border-collapse scrolldown">
                            <thead>
                                <tr className="bg-[#F6F6F6] h-[41.9px]">
                                    <th className={`text-base font-bold px-4 border-r border-border-dark`}>User Name</th>
                                    <th className={`text-base font-bold px-4 border-r border-border-dark`}>Campaign Name</th>
                                    <th className={`text-base font-bold px-4 border-r border-border-dark`}>Host</th>
                                    <th className={`text-base font-bold px-4 border-r border-border-dark`}>Request </th>
                                    <th className={`text-base font-bold px-4 border-r border-border-dark`}>Response</th>
                                    <th className={`text-base font-bold px-4 border-r border-border-dark`}>Status Code</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white w-full">
                                {data.map((item, index) => (
                                    <tr key={index} className="border-b border-border-dark">
                                        <td className={`text-sm text-center font-bold px-2 py-3 border-r border-border-dark`}>{item.userId?.name || '-'}</td>
                                        <td className={`text-sm text-center font-bold px-2 py-3 border-r border-border-dark`}>{item.campaignId?.title || '-'}</td>
                                        <td className={`text-sm text-center font-bold px-2 py-3 border-r border-border-dark`}>{item?.host || '-'}</td>
                                        <td className={`text-sm text-center font-bold px-2 py-3 border-r border-border-dark`}>
                                            {item?.requestBody ? <button
                                                className={`text-sm py-2 px-6 bg-black rounded-2xl mx-auto text-white font-semibold`}
                                                onClick={() => handleOpenModal(item?.requestBody)}>View</button> : '-'}
                                        </td>
                                        <td className={`text-sm text-center font-bold px-2 py-3 border-r border-border-dark`} onClick={() => handleOpenModal(item?.response)}>

                                            <button
                                                className={`text-sm py-2 px-6 bg-black rounded-2xl mx-auto text-white font-semibold`}
                                                onClick={() => handleOpenModal(item?.response || item?.error)}>
                                                View
                                            </button>
                                        </td>
                                        <td className={`${getStatusColorClass(item.responseStatus)} text-sm text-center font-bold px-2 py-3 border-r border-border-dark`}>
                                            {item.responseStatus || '-'}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className="text-center py-4">No leads found.</p>
                    )
                )}
            </div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                rowsPerPage={rowsPerPage}
                onPageChange={setCurrentPage}
                onRowsPerPageChange={(e) => {
                    setRowsPerPage(+e.target.value);
                    setCurrentPage(1);
                }}
                totalData={totalData}
            />

            <ApiLogsModel
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                json={modelData}
            />
        </div>
    );
};

export default LeadHistoryTable;
