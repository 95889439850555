import React from 'react';
import Select, { components } from 'react-select';

export const timelineOptions = [
    { value: "this-year", label: "This Year" },
    { value: "this-month", label: "This Month" },
    { value: "this-week", label: "This Week" },
    { value: "today", label: "Today" },
    { value: "custom", label: "Custom" },
];


const TimeframeDropdown = ({ filter, handleFilterChange }) => {
    const [menuIsOpen, setMenuIsOpen] = React.useState(false);

    const ValueContainer = ({ children, ...props }) => {
        return (
            <components.ValueContainer {...props} >
                {filter ? <span className='text-gray-600'>Timeframe: <b>{timelineOptions.find((o) => o.value === filter)?.label}</b></span> : children}
            </components.ValueContainer>
        );
    };


    const customStyles = {
        control: (base, state) => ({
            ...base,
            minHeight: 'initial',
            cursor: 'pointer',
            borderRadius: "15px",
            padding: "8px",
            background: "white",
            borderColor: "gray"

        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '2px 8px',
            overflow: 'visible',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
        }),
    };

    return (
        <div className=''
            onClick={(e) => {
                e.stopPropagation()
                setMenuIsOpen(!menuIsOpen)
            }}
        >
            <Select
                options={timelineOptions}
                value={filter}
                onChange={handleFilterChange}
                components={{ ValueContainer }}
                styles={customStyles}
                placeholder="Select Timeframe"
                menuIsOpen={menuIsOpen}
            />
        </div>
    );
};

export default TimeframeDropdown;
