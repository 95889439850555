import React, { useCallback, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import apiService from "../../apiService";
import {
  Chart as ChartJS,
  BarElement,
  ArcElement,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { isUserAdminOrSubAdmin } from "../../utils/function";
import {
  getUserRoleFromToken,
  isAdmin,
  isStaffVerifier,
} from "../../utils/auth";
import { roleList } from "../../utils/constant";
import { toast } from "react-toastify";
import LeadList from "../../components/table/LeadList";
import CustomSkeleton from "../../components/shared/Skeleton";
import TimeframeDropdown, { timelineOptions } from "../../components/shared/TimeframeDropdown";
import CountUp from "react-countup";
import { RadialLinearScale } from "chart.js";
import OverlayedDoughnutCharts from "./OverlayedDoughnutCharts";
import { Colors } from "chart.js";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  BarElement,
  ArcElement,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  ChartDataLabels,
  Tooltip,
  RadialLinearScale,
  Colors
);

const customStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: "initial",
    cursor: "pointer",
    borderRadius: "15px",
    padding: "8px",
    background: "white",
    borderColor: "gray",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 8px",
    overflow: "visible",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: "0 8px",
  }),
};

const DashBoard = () => {
  const [filter, setFilter] = useState("this-month");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [openFilter, setOpenFilter] = useState(false);
  // dashboard details
  const [cardData, setCardData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [pieChartData, setPieChartData] = useState(null);

  const userType = getUserRoleFromToken();
  const isUserAdmin = isAdmin();

  const downloadRef = useRef(null);

  const toggleFilterModel = (event) => {
    event.stopPropagation();
    if (!openFilter) {
      setOpenFilter((prevState) => {
        return !prevState;
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        downloadRef.current &&
        !downloadRef.current.contains(event.target) &&
        openFilter
      ) {
        setOpenFilter(false);
      }
    };

    if (openFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openFilter]);

  const fetchUserLeadDetails = useCallback(async () => {
    setLoading(true);
    const data = await apiService.dashboard.getUserLeadDetails({
      status: "",
      userId: selectedUser,
      userType: selectedRole,
      timeline: filter,
      startDate: startDate ? startDate.toISOString() : "",
      endDate: endDate ? endDate.toISOString() : "",
    });

    setCardData(data?.data?.result);
    setLoading(false);
  }, [endDate, filter, selectedRole, selectedUser, startDate]);

  const fetchBarChartData = useCallback(async () => {
    setLoading(true);
    const data = await apiService.dashboard.getBarChartData({
      status: "",
      userId: selectedUser,
      userType: selectedRole,
      timeline: filter,
      startDate: startDate ? startDate.toISOString() : "",
      endDate: endDate ? endDate.toISOString() : "",
    });
    setBarChartData(data.data);
    setLoading(false);
  }, [endDate, filter, selectedRole, selectedUser, startDate]);

  const fetchPieChartData = useCallback(async () => {
    setLoading(true);
    const data = await apiService.dashboard.getPieChartData({
      status: "",
      userId: selectedUser,
      userType: selectedRole,
      timeline: filter,
      startDate: startDate ? startDate.toISOString() : "",
      endDate: endDate ? endDate.toISOString() : "",
    });
    setPieChartData(data.data);
    setLoading(false);
  }, [endDate, filter, selectedRole, selectedUser, startDate]);

  const fetchUsers = useCallback(async () => {
    try {
      if (userType === "subAdmin" || (selectedRole && selectedRole !== "all")) {
        const result = await apiService.auth.getUser(selectedRole);
        const users = result?.data?.users || [];
        setUserList(
          users?.map((user) => ({ label: user.name, value: user._id }))
        );
      } else {
        setUserList([]);
        setSelectedUser("");
      }
    } catch (error) {
      toast.error("Failed to fetch user list.");
    }
  }, [selectedRole, userType]);

  useEffect(() => {
    if (selectedRole) {
      fetchUsers();
    }
  }, [fetchUsers, selectedRole]);

  useEffect(() => {
    if (filter === "custom" && startDate && endDate) {
      fetchUserLeadDetails();
      fetchBarChartData();
      fetchPieChartData();
    } else if (filter !== "custom") {
      fetchUserLeadDetails();
      fetchBarChartData();
      fetchPieChartData();
    }
  }, [
    selectedRole,
    selectedUser,
    filter,
    startDate,
    endDate,
    fetchUserLeadDetails,
    fetchBarChartData,
    fetchPieChartData,
  ]);

  const handleFilterChange = (option) => {
    setFilter(option.value);
    if (option.value !== "custom") {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleRoleChange = (option) => {
    setSelectedRole(option.value);
    setSelectedUser("");
    setUserList([]);
  };

  const handleUserChange = (option) => {
    setSelectedUser(option.value);
  };

  return (
    <div className="w-full relative rounded-[20px] overflow-hidden sm:p-4 p-2">

      {/* Filters */}
      <div className="flex gap-5 items-center justify-between">
        <div className="flex flex-wrap gap-5">
          <div className="max-w-[30%] min-w-[280px]">
            <TimeframeDropdown
              filter={filter}
              handleFilterChange={handleFilterChange}
            />
          </div>

          {/* Date Picker for Custom */}
          {filter === "custom" && (
            <div className="flex items-center sm:gap-4 gap-2 w-full max-w-[60%]">
              <div>
                <div className=" p-[13.9px] flex-1 w-full bg-white rounded-[18.47px] !min-w-[188px] border border-[#EFF0F6] custom-dropdown">
                  <div className=" relative">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className=" w-full  custom-placeholder outline-none "
                      placeholderText="From"
                    />
                    <i className="fa-solid fa-caret-down text-black absolute top-1 right-2 "></i>
                  </div>
                </div>
              </div>
              <div>
                <div className=" p-[13.9px]  !min-w-[188px] flex-1 w-full bg-white rounded-[18.47px] border border-[#EFF0F6] custom-dropdown ">
                  <div className="relative">
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className=" bg-transparent w-full  custom-placeholder  outline-none"
                      placeholderText="To"
                    />
                    <i className="fa-solid fa-caret-down text-black absolute top-1 right-2 "></i>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {isUserAdminOrSubAdmin(userType) &&
          <div className="relative">
            <button onClick={toggleFilterModel} className="flex items-center gap-2 border p-2 rounded-lg bg-white">
              Filter by <FilterIcon className="h-4 w-4 cursor-pointer" fill={(selectedRole || selectedUser) && "#0000FF"} />
            </button>
            {openFilter && (
              <div
                className="absolute top-12 z-50 shadow-lg rounded-lg right-0 p-2 bg-white "
                ref={downloadRef}
              >
                <div className="w-[300px] relative rounded-lg px-2">
                  <div className="flex flex-col gap-5">
                    {isUserAdmin && (
                      <div>
                        <label
                          htmlFor=""
                          className="ml-2 text-gray-600 text-base"
                        >
                          User Type
                        </label>
                        <Select
                          options={roleList}
                          value={roleList.find((o) => o.value === selectedRole)}
                          onChange={handleRoleChange}
                          styles={customStyles}
                          placeholder="Select User role"
                        />
                      </div>
                    )}

                    {isUserAdminOrSubAdmin(userType) && (
                      <div>
                        <label
                          htmlFor=""
                          className="ml-2 text-gray-600 text-base"
                        >
                          User
                        </label>
                        <Select
                          options={[{ label: "All", value: "" }, ...userList]}
                          value={userList.find((o) => o.value === selectedUser)}
                          onChange={handleUserChange}
                          styles={customStyles}
                          placeholder="Select User role"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>}
      </div>

      {/* Banner START*/}
      <div className="bg-[#F9C159] max-h-[150px] relative rounded-lg shadow-lg flex flex-col md:flex-row items-center mt-6">
        <div className="md:w-1/2 p-8 fadeInLeft">
          <h1 className="text-3xl font-bold text-gray-800 mb-4">Welcome back</h1>
          <p className="text-gray-800 font-medium">You're doing an amazing job! Keep the momentum going and watch your efforts turn into success!</p>
        </div>
        <img className="hidden md:block h-[250px] max-w-[250px] absolute bottom-0 right-[10%] popUp" src="/assets/image/Businessman.png" alt="..." />
      </div>
      {/* Banner END*/}

      {/* Cards START*/}
      <div className="mt-6">
        <div className="flex items-center gap-4 justify-between overflow-auto">
          {loading ? (
            <div className="w-full h-[200px] flex items-center gap-5">
              <CustomSkeleton count={5} height="150px" width="150px" />
            </div>
          ) : cardData?.length > 0 && (
            cardData.map((da) => (
              <div
                key={da?.title || Math.random()}
                className="bg-gray-300 p-4 rounded-lg text-center h-full w-full cursor-pointer"
                onClick={() => navigate(`/crm/leads?status=${da?.title}&timeframe=${filter === 'custom' ? startDate + '-custom-' + endDate : filter}`)}
              >
                <div
                  style={{
                    background: da?.color || '#FFBF00'
                  }}
                  className="w-20 h-20 border-4 border-white rounded-full flex items-center justify-center mx-auto mb-4">
                  <span className="text-3xl font-bold">
                    <CountUp
                      duration={2}
                      className="counter text-gray-800"
                      end={+da?.value || 0}
                    />
                  </span>
                </div>
                <p className="font-semibold text-gray-800">{da?.title || ''}</p>
              </div>
            ))
          )}
        </div>


      </div>
      {/* Cards END*/}

      {/* Chart START*/}
      <div className="flex gap-5 max-lg:flex-col flex-wrap mt-6">
        <div
          style={{
            flex: 1.5,
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
          }}
          className="bg-white py-[12.91px] px-[18.47px] border rounded-[18.47px]"
        >
          <div className="max-h-[300px] h-full flex items-center justify-center flex-col gap-2 pt-5">
            <p className="w-full text-start font-bold">Leads Created in {timelineOptions.find((op) => op.value === filter).label}</p>

            {!loading ? (
              barChartData?.isDataExist ? (
                <Bar
                  className="!h-full !w-full "
                  data={{
                    labels: barChartData?.labels,
                    datasets: [
                      {
                        label: "Leads",
                        data: barChartData?.data,
                        borderWidth: 0,
                        maxBarThickness: 80,
                        borderRadius: 15,
                        backgroundColor: (context) => {
                          const chart = context.chart;
                          const { ctx, chartArea } = chart;

                          if (!chartArea) {
                            return;
                          }

                          const gradient = ctx.createLinearGradient(
                            0,
                            chartArea.bottom,
                            0,
                            chartArea.top
                          );
                          gradient.addColorStop(0, "#854aec");
                          gradient.addColorStop(1, "#1B59F8");
                          return gradient;
                        },
                        datalabels: {
                          color: 'white'
                        }
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                        ticks: {
                          color: "#6B7280",
                        },
                      },
                      y: {
                        beginAtZero: true,
                        grid: {
                          display: false,
                        },
                        ticks: {
                          color: "#7e22ce",
                          stepSize: 5,
                        },
                      },
                    },
                  }}
                  plugins={{
                    datalabels: {
                      anchor: "end",
                      align: "top",
                      font: {
                        weight: "bold",
                      },
                      formatter: (value) => value
                    },
                  }}
                />
              ) : (
                <div className="min-h-[250px] flex items-center justify-center font-bold" >No data found</div>
              )
            ) : (
              <div className="w-full h-[250px]">
                <CustomSkeleton />
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            flex: 1,
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
          }}
          className="bg-white py-[12.91px] px-[18.47px] border rounded-[18.47px]"
        >
          <div className="max-h-[300px] h-full flex items-center justify-center flex-col gap-2 pt-5">
            <p className="w-full text-start font-bold">Leads By Status</p>

            {loading ? (
              <div className="h-full w-full">
                <CustomSkeleton height="250px" />
              </div>
            ) : pieChartData?.isDataExist ? (
              <OverlayedDoughnutCharts
                data={pieChartData}
              />
            ) : (
              <div className="min-h-[250px] flex items-center justify-center font-bold">No data found</div>
            )}
          </div>
        </div>
      </div>
      {/* Chart END*/}

      {/* Bottom table */}
      {!isStaffVerifier() && (
        <div className="mt-8 lg:flex-[8] w-full pb-3 bg-white border border-border-color shadow-xl rounded-[18.47px]"
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
          }}
        >
          <LeadList loading={loading} />
        </div>
      )}
      {/* Bottom table */}
    </div>
  );
};

export default DashBoard;
